@use '../variables' as vars;

@keyframes spinning {
    0%{
        transform: rotate(0deg);
    }
    50%{
        transform: rotate(180deg);
    }
    100%{
        transform: rotate(360deg);
    }
}

@keyframes blur-in {
    0%{
        background-color: rgba($color: #fff, $alpha: 0);
        backdrop-filter: blur(0px);
    }
    100%{
        background-color: rgba($color: #121212, $alpha: .1);
        backdrop-filter: blur(3px);
    }
}

.loader{
    &.spinner, &.spinner::after, &.spinner::before{
        border: 8px solid transparent;
        border-top: 8px solid vars.$primary;
        border-bottom: 8px solid vars.$primary;
        border-radius: 50%;
        animation: spinning .5s linear infinite;
    }

    &.spinner{
        position: absolute;
        width: 80px;
        height: 80px;

        display: flex;
        align-items: center;
        justify-content: center;

        &::after{
            content: "";
            display: block;
            width: 40%;
            height: 40%;
            animation-direction: reverse;
            animation-duration: .25s;
        }
    }

    &.bg{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        animation: blur-in .2s linear forwards;
    }
}