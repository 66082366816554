@use '../../variables' as vars;

#navigation{
    position: sticky;
    top: 0;
    z-index: 3;
    background-color: #fff;
}

#navigation.collapsed{
    /* padding-bottom: 20px; */
    box-shadow: 0px 1px 14px 0px rgba(0, 0, 0, 0.11);
}

#menuMobile{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;

    &.hidden{
        display: none;
    }

    .content{
        background-color: #fff;

        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        position: relative;
        max-height: 80vh;
        padding: 80px 0;

        nav{
            overflow-y: scroll;
            width: 100%;
        }

        .menu_item{
            display: block;
            font-size: 1.5rem;
            color: vars.$font_default;
            text-decoration: none;
            margin-bottom: 55px;
            text-align: center;
            text-transform: uppercase;

            .menu_item{
                font-size: 1.2rem;
                margin-bottom: 10px;

                &:first-child{
                    margin-top: 20px;
                }
    
                &:last-child{
                    margin-bottom: 20px;
                }
            }

            &:last-child{
                margin-bottom: 0;
            }
        }

        button{
            background-color: transparent;
            border: none;
            position: absolute;
            top: 25px;
            right: 25px;
        }
    }
}

.submenu{
    display: none;
    background-color: #fff;
    width: 100%;
    filter: drop-shadow(0 14px 18px rgba(0,0,0,0.17));
    justify-content: center;
    align-items: center;
    height: 140px;
    transition-duration: .2s;

    @media screen and (min-width: vars.$tablet) {
        display: flex;
    }

    &.hidden{
        display: none;
    }

    &.collapsed{
        height: 40px;
    }

    .menu_item{
        color: vars.$font_default;
        text-decoration: none;
        margin-right: 50px;
        transition-duration: .2s;
        font-size: .9em;

        &:last-child{
            margin-right: 0;
        }

        &:hover{
            transform: scale(1.2);
        }
    }
}

#menu{
    background-color: #fff;
    transition: .2s;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;

    @media screen and (min-width: vars.$tablet){
        justify-content: center;
        padding: 0;
    }

    &.collapsed{
        #logo{
            width: 150px !important;
        }
    }

    .menu_item{
        margin-right: 0px;
        text-transform: uppercase;
        text-decoration: none;
        color: inherit;
        display: block;
        transition-duration: .2s;
        
        &:not(#logo){
            display: none;

            @media screen and (min-width: vars.$tablet){
                display: inline-block;
            }
        }

        &.tab{
            &::after{
                display: grid;
                grid-template-columns: 1fr auto;
                content: "";
                background-image: url(../../assets/arrow_down.svg);
                display: inline-block;
                width: .75em;
                height: .75em;
                background-position: 0 50%;
                background-size: contain;
                background-repeat: no-repeat;
                margin-left: 5px;
                transition-duration: .4s;
            }
        
            &.collapsed{
                &::after{
                    transform: rotateZ(180deg);
                }
            }
        }

        &:not(#logo):hover{
            transform: scale(1.2);
        }

        @media screen and (min-width: vars.$tablet){
            margin-right: 40px;
        }
        
        @media screen and (min-width: vars.$desktop){
            margin-right: 70px;
        }

        &#logo{
            width: 223px;
            transition-duration: .2s;

            @media screen and (min-width: vars.$desktop){
                width: 372px;
            }

            img{
                width: 100%;
                object-fit: cover;
            }
        }
        
        &:last-of-type{
            margin-right: 0;
        }
    }

    button{
        background-color: transparent;
        border: none;

        @media screen and (min-width: vars.$tablet) {
            display: none;
        }
    }
}

nav.programs{
    height: 48px;
    display: flex;
    align-items: center;

    .program{
        text-decoration: none;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        color: vars.$primary;
        background-color: rgba(255, 255, 255, 0.5);
        padding: 3px 6px;
        border-radius: 6px;
        font-size: 10px;
        font-weight: bolder;

        .title.short{
            text-transform: uppercase;
        }

        @media screen and (min-width: vars.$tablet) {
            font-size: .75rem;
            .title.short{
                display: none;
            }
        }

        @media screen and (max-width: vars.$tablet) {
            font-size: 1rem;
            .title.full{
                display: none;
            }
        }

        &:last-child{
            margin-right: 0;
        }

        &::before{
            content: "";
            display: inline-block;
            background-repeat: no-repeat;
            background-position: 0 0;
            background-size: cover;
            width: 1rem;
            height: 1rem;
            margin-right: 6px;
        }

        &#pp::before{
            background-image: url(../../assets/programs/icons/pp_icon.svg);
        }

        &#ss::before{
            background-image: url(../../assets/programs/icons/ss_icon.svg);
        }

        &#wl::before{
            background-image: url(../../assets/programs/icons/wl_icon.svg);
        }

        &#st::before{
            background-image: url(../../assets/programs/icons/st_icon.svg);
        }
    }
}

.rainbow .content{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#google_translate_element, iframe.skiptranslate{
    display: none;
}

#language-toggle{
    background-color: transparent;
    display: inline;
    width: fit-content;
    padding: 5px;
    color: #121212;
}