@use '../../variables' as vars;

.registration.form{
    position: relative;
    border: 1px solid vars.$secondary;
    filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.25));
    background-color: #fff;
    padding: 16px;
    border-radius: 20px;
    overflow: hidden;
    
    header{
        margin-bottom: 20px;
    }

    .confirmed{
        width: 150px;
        margin: 0 auto;
    }
}