@use '../../variables' as vars;

footer{
    padding: 60px 0;
    background-color: vars.$secondary_bg;

    .content{
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;

        @media screen and (min-width: vars.$desktop) {
            flex-flow: row;
            justify-content: space-between;
        }

        .logo{
            margin-bottom: 30px;
        }

        .item-grid{
            margin-bottom: 30px;
            display: grid;
            gap: 20px;

            &.navigation{
                grid-template-columns: 1fr 1fr;

                a{
                    color: vars.$font_default;
                    text-decoration: none;
                    text-align: center;
                }

                @media screen and (min-width: vars.$tablet) {
                    grid-template-columns: repeat(4, 1fr);
                }

                @media screen and (min-width: vars.$desktop) {
                    grid-template-columns: repeat(2, 1fr);
                }
            }

            &.additional{
                text-align: center;
                .socials{
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                }

                @media screen and (min-width: vars.$tablet) {
                    grid-template-columns: repeat(2, 1fr);
                }
            }
        }
    }

    .copywrite{
        text-align: center;
        margin: 20px 20px 0;
        font-size: .75em;
    }
}