.collapse{
    .head::after{
        content: "";
        background-image: url(../../assets/arrow_down.svg);
        display: inline-block;
        width: .75em;
        height: .75em;
        background-position: 0 50%;
        background-size: contain;
        background-repeat: no-repeat;
        margin-left: 5px;
        transition-duration: .4s;
    }

    &.collapsed{
        .head::after{
            transform: rotateZ(180deg);
        }

        .body{
            display: none;
        }
    }
}