@use '../../variables' as vars;

#programs{
    display: flex;
    flex-flow: column;
    justify-content: center;
    width: 100%;
    gap: 30px;

    @media screen and (min-width: vars.$tablet) {
        flex-flow: wrap;
        gap: 30px 100px;
    }

    @media screen and (min-width: vars.$desktop){
        gap: 30px 160px;
    }

    .program{
        width: 250px;
        transition: .2s;

        &:last-child{
            margin-bottom: 0;
        }

        @media screen and (max-width: vars.$tablet) {
            margin: 0 auto;
            margin-bottom: 30px;
        }

        @media screen and (min-width: vars.$tablet) {
            width: 200px;
        }

        @media screen and (min-width: vars.$desktop){
            width: 250px;
            text-decoration: none;
        }

        &:hover{
            scale: 1.1;
        }

        .icon{
            width: 100%;
            object-fit: cover;
            border-radius: 20px;
            filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.25));
        }

        .title{
            text-align: center;
            font-size: 1.5em;
            font-weight: bold;
            text-wrap: balance;
            margin-top: 10px;
            color: vars.$primary_hover;
        }
    }
}

.registration{
    width: 100%;
    margin: 0 auto;

    @media screen and (min-width: vars.$desktop) {
        width: 928px;
    }
}

.feedback{
    display: flex;
    justify-content: center;
    margin-top: 20px;

    button:first-child{
        margin-right: 20px;

        @media screen and (min-width: vars.$desktop) {
            margin-right: 120px;
        }
    }
}