@use '../../../variables.scss' as vars;

$wa_user_msg_bg: #e1ffc7;
$wa_ai_msg_bg: #f1f1f1;
$wa_header_bg: #0d835b;

.program-overview{
    display: flex;
    gap: 40px;
    align-items: center;
    flex-flow: column-reverse;

    @media screen and (min-width: vars.$desktop){
        flex-flow: row;
    }

    .preview{
        font-family: "Roboto", sans-serif;
        width: 300px;
        flex-shrink: 0;
        header{
            background-color: $wa_header_bg;
            color: #fff;
            display: flex;
            align-items: center;
            gap: 5px;
            padding: 5px;
            border-radius: 10px;
            width: 100%;

            .avatar{
                height: 35px;
                width: 35px;
                border-radius: 50%;
            }

            .title{
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 17px;
            }
        }
    }

    .messages{
        width: 100%;
    
        display: flex;
        flex-flow: column;
        gap: 8px;
        padding: 10px;
    
        .message{
            clear: both;
            line-height: 18px;
            font-size: 15px;
            padding: 8px 8px 12px;
            position: relative;
            max-width: 85%;
            word-wrap: break-word;
            z-index: -1;
            color: vars.$font_default;

            ul, ol{
                list-style-position: inside;
                padding: 5px 0 5px 10px;
                
                li{
                    &::marker{
                        font-weight: bold;
                    }
                }
            }

            img{
                width: calc(100% + 12px);
                border-radius: 5px;
                position: relative;
                top: -6px;
                left: -10px;
            }
    
            .metadata{
                display: inline-block;
                float: right;
                padding: 0 0 0 7px;
                position: relative;
                bottom: -4px;

                & .time{
                    color: rgba(0, 0, 0, .45);
                    font-size: 11px;
                    display: inline-block;
                }

                & .tick{
                    display: inline-block;
                    margin-left: 2px;
                    position: relative;
                    top: 4px;
                    height: 16px;
                    width: 16px;
                    background-image: url(../../../assets/icons/whatsapp_msg_read.svg);
                }
            }
    
            &::after{
                position: absolute;
                content: "";
                width: 0;
                height: 0;
                border-style: solid;
            }
    
            &.user{
                background-color: $wa_user_msg_bg;
                border-radius: 5px 0px 5px 5px;
                align-self: flex-end;
    
                &::after{
                    border-width: 0px 0 10px 10px;
                    border-color: transparent transparent transparent $wa_user_msg_bg;
                    top: 0;
                    right: -10px;
                }
            }
    
            &.ai{
                background: $wa_ai_msg_bg;
                border-radius: 0px 5px 5px 5px;
                align-self: flex-start;
    
                &::after{
                    border-width: 0px 10px 10px 0;
                    border-color: transparent $wa_ai_msg_bg transparent transparent;
                    top: 0;
                    left: -10px;
                }
            }
        }
    }

    .info{
        text-align: center;
    
        ul{
            text-align: left;
            padding: 0 80px;
        }

        .subtitle{
            margin-bottom: 10px;
        }
    }
}