.error{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);

    .wrapper{
        display: flex;

        span{
            padding: 10px;
        }

        .code{
            border-right: 2px solid black;
        }
    }
}